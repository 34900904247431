import React, { useEffect, useState } from 'react';
import './App.css';
// import twitterLogo from './assets/twitter-logo.svg';
import CandyMachine from './CandyMachine';
import backgroundVideo from './assets/video/background.mp4';
import backgroundMusic from './assets/audio/panther.mp3';
// import Nav from './components/Nav';

// Constants
const TWITTER_HANDLE = 'iiwok';
const TWITTER_LINK = `https://twitter.com/${TWITTER_HANDLE}`;
const INSTAGRAM_HANDLE = 'iiwoks';
const INSTAGRAM_LINK=`https://twitter.com/${INSTAGRAM_HANDLE}`;

const App = () => {
  const [walletAddress, setWalletAddress] = useState(null);

  const checkIfWalletIsConnected = async () => {
    try {
      const { solana } = window;
      if (solana) {
        if (solana.isPhantom) {
          console.log('Phantom wallet found!');
          const response = await solana.connect({ onlyIfTrusted: true });
          console.log(
            'Connected with Public Key:',
            response.publicKey.toString()
          );
          setWalletAddress(response.publicKey.toString());
        }
      } else {
        alert('Solana object not found! Get a Phantom Wallet 👻');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const connectWallet = async () => {
    const { solana } = window;
    if (solana) {
      const response = await solana.connect();
      console.log('Connected with Public Key:', response.publicKey.toString());
      setWalletAddress(response.publicKey.toString());
    }
  };

  const renderNotConnectedContainer = () => (
    <button 
      className="cta-button connect-wallet-button"
      onClick={connectWallet}
      >
        Connect to Wallet
    </button>
  );

  useEffect(() => {
    const onLoad = async () => {
      await checkIfWalletIsConnected();
    };
    window.addEventListener('load', onLoad);
    return () => window.removeEventListener('load', onLoad);
  }, []);

  return (
    <div className="App">
      <video autoPlay loop muted className='background-video'>
        <source src={backgroundVideo} type='video/mp4' />
      </video>
    {/* <embed src="/html/Kalimba.mp3" loop="true" autostart="true" width="2" height="0"></embed> */}
    <audio autoPlay loop>
      <source src={backgroundMusic} type='audio/mpeg' />
    </audio>
      <div className="container">
      {/* <Nav /> */}
        <div className="header-container">
          <p className="header">bombom</p>
          <p className="sub-text">NFT packs by iiwoks NFT packs by iiwoks NFT packs by iiwoks NFT packs by iiwoks NFT packs by iiwoks NFT packs by iiwoks NFT packs by iiwoks NFT packs by iiwoks NFT packs by iiwoks NFT packs by iiwoks NFT packs by iiwoks NFT packs by iiwoks NFT packs by iiwoks NFT packs by iiwoks NFT packs by iiwoks NFT packs by iiwoks NFT packs by iiwoks NFT packs by iiwoks</p>
          {!walletAddress && renderNotConnectedContainer()}
          {walletAddress && <CandyMachine walletAddress={window.solana} />}
        </div>
        
        
        {/* <div className="footer-container">
         
          <img alt="Twitter Logo" className="twitter-logo" src={twitterLogo} />
          <a
            className="footer-text"
            href={TWITTER_LINK}
            target="_blank"
            rel="noreferrer"
          >{`Learn more about @${TWITTER_HANDLE}`}</a>

        </div> */}
      </div>
    </div>
  );
};

export default App;
